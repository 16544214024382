export interface AutomationWSMessage {
  id: string;
  action_type: string;
  description: string;
  interactive_message: any;
  action_status: string;
  // Add other properties as needed based on your backend message structure
}

interface AutomationResponseWSMessage {
  id: string;
  responseType: string;
  data: any;
  action_status: string;
}

export type AutomationStateType = 'idle' | 'running';

export const processWebSocketMessage = (
  message: AutomationWSMessage,
  sendWSJsonMessage: (response: AutomationResponseWSMessage) => void
) => {
  const { action_type } = message;

  switch (action_type) {
    case 'get-browser-screenshot':
      chrome.runtime.sendMessage(
        {
          type: 'requestScreenshot'
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'screenshot',
            data: {
              screenshot: response.screenshotUrl
            },
            action_status: 'completed'
          });
        }
      );
      break;
    case 'get-browser-screenshot-w-label':
      chrome.runtime.sendMessage(
        {
          type: 'requestScreenshotWithLabel'
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'screenshot',
            data: {
              screenshot: response.screenshotUrl
            },
            action_status: 'completed'
          });
        }
      );
      break;
    case 'browser-action':
      chrome.runtime.sendMessage(
        {
          type: message.interactive_message.action_type,
          target: message.interactive_message.value,
          querySelector: message.interactive_message.target_label,
          description: message.description,
          performAction: true
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'update-status',
            data: response,
            action_status: 'completed'
          });
        }
      );
      break;
    case 'browser-navigate':
      chrome.runtime.sendMessage(
        {
          type: 'navigation',
          target: message.interactive_message['url']
        },
        (response) => {
          // Add a 2 second delay before sending response
          sendWSJsonMessage({
            id: message.id,
            responseType: 'update-status',
            data: null,
            action_status: 'completed'
          });
        }
      );
      break;
    case 'inspect-browser-dom':
      chrome.runtime.sendMessage(
        {
          type: 'findTagInDom',
          target: message.interactive_message.value
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'update-status',
            data: response,
            action_status: 'completed'
          });
        }
      );
      break;
    case 'browser-scroll':
      chrome.runtime.sendMessage(
        {
          type: 'scrollPage'
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'update-status',
            data: response,
            action_status: 'completed'
          });
        }
      );
      break;
    case 'get-dom':
      chrome.runtime.sendMessage(
        {
          type: 'getDom'
        },
        (response) => {
          sendWSJsonMessage({
            id: message.id,
            responseType: 'update-status',
            data: response,
            action_status: 'completed'
          });
        }
      );
      break;
    default:
      break;
  }
};

export const statusStyles: Record<
  string,
  { backgroundColor: string; color: string }
> = {
  completed: { backgroundColor: '#e8f5e9', color: '#2e7d32' },
  running: { backgroundColor: '#cde5ff', color: '#004b93' },
  stopped: { backgroundColor: '#ffebee', color: '#d32f2f' },
  failed: { backgroundColor: '#ffebee', color: '#d32f2f' },
  pending: { backgroundColor: '#fff3e0', color: '#ed6c02' },
  queueing: { backgroundColor: '#fff3e0', color: '#ed6c02' },
  terminated: { backgroundColor: '#ffebee', color: '#d32f2f' }
  // Add more statuses and their styles here
};
