import Box from '@mui/material/Box';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import WebNavButton from '../NavigationButton/WebNavButton';

interface WebNavSidebarProps {
  isManager: boolean;
  hasAccessToAutomation: boolean;
}

const WebNavSidebar = (props: WebNavSidebarProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        boxSizing: 'border-box',
        gap: 1
      }}
    >
      <WebNavButton icon={HomeIcon} text="Home" to="/home" />
      <WebNavButton icon={ChatIcon} text="Guide" to="/guide" />
      {props.isManager && (
        <WebNavButton
          icon={AutoFixHighIcon}
          text="Skills"
          to="/documentation/list"
        />
      )}
      {props.hasAccessToAutomation && (
        <WebNavButton icon={AssignmentIcon} text="AI Jobs" to="/automation" />
      )}
      {props.hasAccessToAutomation && (
        <WebNavButton icon={SmartToyIcon} text="AI Fleet" to="/ai-fleet" />
      )}
      <WebNavButton icon={SettingsIcon} text="Settings" to="/profile" />
    </Box>
  );
};

export default WebNavSidebar;
