import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tabs,
  Tab,
  CircularProgress
} from '@mui/material';
import { useSnackbar } from '../../../context/SnackbarContext';
import {
  SkillSummary,
  getSkillsSummaryCreateByUser
} from '../../../api/skills';
import { getIngestedDocuments } from '../../../api/patiaAI';
import { useUserContextController } from '../../../context/UserContext';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import SlackLogo from '../../../assets/SlackLogo.svg';
import NotionLogo from '../../../assets/NotionLogo.svg';
import GoogleGLogo from '../../../assets/GoogleGLogo.svg';
import DokaiIcon from '../../../assets/dokai-icon.svg';
import Pagination from '../../../components/pagination';
import DocumentListActions from './DocumentListActions';
import {
  ExtensionName,
  getExtensionName
} from '../../../utils/ManifestChecker';
import { isChromeExtApp, isSopai } from '../../../utils/chromeHelper';
import { Navigate } from 'react-router-dom';
import { SOPAI_WEB_BASE_URL } from '../../../api/constants';

const DocumentationList: React.FC = () => {
  const [userContext] = useUserContextController();
  const { user, agentName } = userContext;
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [skillList, setSkillList] = useState<SkillSummary[]>([]);
  const [ingestedDocuments, setIngestedDocuments] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const pageSize = 10;
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const extensionName = !isChromeExtApp() ? null : getExtensionName();

  const onErrorHandler = () => {
    setLoading(false);
    showSnackbar('Failed to fetch data', 'error');
  };

  const fetchSkills = async (page: number = 1) => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    getSkillsSummaryCreateByUser(user?.user_id || '', pageSize, offset)
      .then((response) => {
        setSkillList(response.skill_list);
        setTotalItems(response.total_count);
        setLoading(false);
      })
      .catch(() => {
        onErrorHandler();
      });
  };

  const fetchIngestedDocuments = async (page: number = 1) => {
    setLoading(true);
    const offset = (page - 1) * pageSize;
    getIngestedDocuments(agentName || 'DEFAULT_AGENT_NAME', pageSize, offset)
      .then((response) => {
        setIngestedDocuments(response.documents);
        setTotalItems(response.total_count);
        setLoading(false);
      })
      .catch(() => {
        onErrorHandler();
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setCurrentPage(1);
    if (newValue === 0) {
      fetchSkills(1);
    } else {
      fetchIngestedDocuments(1);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (activeTab === 0) {
      fetchSkills(page);
    } else {
      fetchIngestedDocuments(page);
    }
  };

  const totalPages = Math.ceil(totalItems / pageSize);

  useEffect(() => {
    fetchSkills(1);
    // To remove warning for exhaustive deps
    // eslint-disable-next-line
  }, []);

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  return (
    <Box
      sx={{
        padding: '10px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box'
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '24px',
          textAlign: 'center',
          marginBottom: '10px'
        }}
      >
        Skills
      </Typography>
      {user?.isManager && extensionName !== ExtensionName.SOPAI && (
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="My Skills" sx={{ textTransform: 'capitalize' }} />
          <Tab label="Agent Skills" sx={{ textTransform: 'capitalize' }} />
        </Tabs>
      )}
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          padding: '10px',
          overflowY: 'auto',
          maxHeight: '100%',
          boxSizing: 'border-box',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              alignItems: 'center'
            }}
          >
            <CircularProgress size={50} />
          </Box>
        )}
        {!loading && activeTab === 0 && (
          <>
            {skillList.length > 0 ? (
              <List>
                {skillList.map((skillSummary: SkillSummary, idx: number) => (
                  <ListItem
                    sx={(theme) => ({
                      border: `1px solid ${theme.customColors.themeNeutralSecondaryColor}`,
                      borderRadius: '4px',
                      overflow: 'hidden',
                      maxWidth: '280px !important',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      margin: 'auto auto 10px auto',
                      cursor: 'pointer',
                      '&:hover': {
                        background: theme.customColors.themePrimary,
                        color: theme.customColors.themeSecondary,
                        borderColor: theme.customColors.themePrimary,
                        svg: { color: theme.customColors.themeSecondary }
                      }
                    })}
                    key={idx}
                    onClick={() => {
                      if (isSopai()) {
                        window.open(
                          `${SOPAI_WEB_BASE_URL}/documentation/details/${skillSummary.id}`,
                          '_blank'
                        );
                      } else {
                        navigate(`/documentation/details/${skillSummary.id}`);
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={(theme) => ({
                        width: '30px',
                        minWidth: '30px',
                        color: theme.customColors.themePrimary
                      })}
                    >
                      <AutoFixHighIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: '12px'
                        }}
                        title={skillSummary.context}
                      >
                        {skillSummary.context}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography sx={{ textAlign: 'center' }}>
                No Skills found
              </Typography>
            )}
          </>
        )}
        {!loading &&
          user?.isManager &&
          extensionName !== ExtensionName.SOPAI &&
          activeTab === 1 && (
            <>
              {ingestedDocuments.length > 0 ? (
                <List>
                  {ingestedDocuments.map((document: any, idx: number) => (
                    <ListItem
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        maxWidth: '280px !important',
                        margin: 'auto auto 10px auto',
                        paddingX: '0',
                        paddingY: '5px'
                      }}
                      key={`${document.id}-${idx}`}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flex: 1,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          paddingRight: '10px',
                          fontSize: '12px'
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            width: '30px',
                            minWidth: '30px',
                            marginRight: '10px'
                          }}
                        >
                          {document.doc_type === 'slack_channels' && (
                            <img
                              src={SlackLogo}
                              alt="Slack Logo"
                              style={{ width: '100%', height: '100%' }}
                            />
                          )}
                          {document.doc_type === 'notion_pages' && (
                            <img
                              src={NotionLogo}
                              alt="Notion Logo"
                              style={{ width: '100%', height: '100%' }}
                            />
                          )}
                          {document.doc_type === 'gdoc' && (
                            <img
                              src={GoogleGLogo}
                              alt="Google Logo"
                              style={{ width: '100%', height: '100%' }}
                            />
                          )}
                          {document.doc_type === 'dokai_skill' && (
                            <img
                              src={DokaiIcon}
                              alt="Dokai Icon"
                              style={{ width: '100%', height: '100%' }}
                            />
                          )}
                        </ListItemIcon>

                        <ListItemText>
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              fontSize: '12px',
                              maxWidth: '300px'
                            }}
                            title={document.name}
                          >
                            {document.name}
                          </Typography>
                        </ListItemText>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          gap: '10px'
                        }}
                      >
                        <DocumentListActions
                          document={document}
                          afterDelete={() => {
                            fetchIngestedDocuments(currentPage);
                          }}
                          isDeleting={isDeleting}
                          setIsDeleting={(value) => setIsDeleting(value)}
                        />
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography sx={{ textAlign: 'center' }}>
                  No Skills found
                </Typography>
              )}
            </>
          )}
      </Box>
      {!loading && totalItems > 0 && (
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0',
            width: 'fit-content',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentationList;
