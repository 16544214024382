import { Box, Button } from '@mui/material';
import { RecordedAction } from '../../pages/teach/recordedAction';

interface ActionToolboxProps {
  recordedActions: RecordedAction[];
  isManager: boolean;
  editMode: boolean;
  onEditClick: () => void;
  onSaveClick: () => void;
}

const ActionToolbox = (props: ActionToolboxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2
      }}
    >
      {props.isManager && (
        <Button
          sx={(theme) => ({
            borderRadius: 3,
            backgroundColor: theme.customColors.themePrimary,
            fontSize: '10px',
            fontWeight: '600',
            my: 1
          })}
          variant="contained"
          onClick={props.editMode ? props.onSaveClick : props.onEditClick}
        >
          {props.editMode ? 'Save skill' : 'Edit skill'}
        </Button>
      )}
    </Box>
  );
};

export default ActionToolbox;
