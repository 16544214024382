import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AutomationJob } from '../../types/automation';
import { Navigate, useNavigate } from "react-router-dom";
import { getAutomationJobsSummaryV2 } from "../../api/automationAgent";
import { useUserContextController } from "../../context/UserContext";
import { statusStyles } from "../../utils/automation";

const truncateJobId = (jobId: string) => {
  return jobId.slice(0, 8) + '...';
};

const AutomationListPage: React.FC = () => {
  const [page, setPage] = useState(0);
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const [jobs, setJobs] = useState<AutomationJob[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [totalItems] = useState(40);
  const navigate = useNavigate();

  const fetchJobs = async () => {
    setLoading(true);
    try {
      const response = await getAutomationJobsSummaryV2(page * rowsPerPage, rowsPerPage);
      setJobs(response);
    } catch (error) {
      console.error('Failed to fetch automation jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (jobId: string) => {
    navigate(`/automation/job/${jobId}`);
  };

  if (!user) {
    return <Navigate to="/home" replace />;
  }
  
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={2}
        pt={2}
        boxShadow={1}
      >
        <Box flex={1} display="flex" justifyContent="center">
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            InputProps={{
              sx: ({
                'input': {
                  paddingLeft: '10px'
                }
              }),
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <IconButton sx={{ ml: 2 }}>
            <FilterListIcon />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          sx={(theme) => ({
            color: theme.customColors.themeSecondary,
            backgroundColor: theme.customColors.themePrimary,
            textTransform: 'capitalize',
            borderRadius: '30px',
            boxShadow: 'none',
          })}
          onClick={() => {
            navigate('/automation/create');
          }}
        >
          Create AI Job
        </Button>
      </Box>
      <Box
        flex={1}
        overflow="auto"
      >
        <TableContainer
          sx={{
            maxHeight: "100%",
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}}>Job ID</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Description</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Status</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => (
                <TableRow
                  key={job.id}
                  hover
                  onClick={() => handleRowClick(job.id)}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer'
                    },
                    '&:nth-of-type(even)': {
                      backgroundColor: '#fcfcfc'
                    }
                  }}
                >
                  <TableCell sx={{ color: '#666' }}>
                    {truncateJobId(job.id)}
                  </TableCell>
                  <TableCell>{job.job_description}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor:
                          statusStyles[job.status]?.backgroundColor || '#e0e0e0',
                        color: statusStyles[job.status]?.color || '#000',
                        padding: '4px 12px',
                        borderRadius: '16px',
                        display: 'inline-block',
                        fontSize: '0.875rem',
                        textTransform: 'capitalize',
                      }}
                    >
                      {job.status}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ color: '#666', textAlign: 'left' }}>
                    {new Date(job.create_ts).toLocaleDateString([], {
                      month: 'short',
                      day: '2-digit'
                    })}{' '}
                    {new Date(job.create_ts).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        padding={2}
        boxShadow={1}
      >
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default AutomationListPage;
