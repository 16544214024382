import { RecordedAction } from '../pages/teach/recordedAction';
import { isSopai } from '../utils/chromeHelper';
import { ExtensionName } from '../utils/ManifestChecker';
import { BASE_URL } from './constants';
import { getUserSessionCookie } from '../utils/SessionCookie';

interface Skill {
  id: string;
  context: string;
  additional_context: string;
  create_ts: string;
  recorded_actions: RecordedAction[];
  audio_segments: Record<string, string>;
}

interface SkillSummary {
  id: string;
  context: string;
  create_ts: string;
}

const flattenTargetBoundingRect = (
  actions: RecordedAction[]
): RecordedAction[] => {
  const flattenedActions = actions.map((action) => {
    if (action.targetBoundingRect) {
      return {
        ...action,
        targetWidth: Math.round(action.targetBoundingRect.width),
        targetHeight: Math.round(action.targetBoundingRect.height),
        targetTop: Math.round(action.targetBoundingRect.top),
        targetLeft: Math.round(action.targetBoundingRect.left),
        targetBoundingRect: undefined
      };
    }
    return action;
  });

  return flattenedActions.map(({ targetBoundingRect, ...rest }) => rest);
};

const unflattenTargetBoundingRect = (
  actions: RecordedAction[]
): RecordedAction[] => {
  return actions.map((action) => {
    if (
      'targetWidth' in action &&
      'targetHeight' in action &&
      'targetTop' in action &&
      'targetLeft' in action
    ) {
      const {
        targetWidth,
        targetHeight,
        targetTop,
        targetLeft,
        ...restAction
      } = action as any;

      return {
        ...restAction,
        targetBoundingRect: {
          width: targetWidth,
          height: targetHeight,
          top: targetTop,
          left: targetLeft
        }
      };
    }
    return action;
  });
};

const createNewSkill = async (
  context: string,
  additional_context: string,
  recorded_actions: RecordedAction[],
  audioId?: string
) => {
  const userSessionToken = getUserSessionCookie();
  const appName = isSopai() ? ExtensionName.SOPAI : ExtensionName.DOKAI;

  const res = await fetch(`${BASE_URL}/skill/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userSessionToken}`,
      'x-app-name': appName
    },
    body: JSON.stringify({
      context,
      additional_context,
      create_ts: new Date().toISOString(),
      recorded_actions: flattenTargetBoundingRect(recorded_actions),
      audioId
    })
  });

  if (!res.ok) {
    throw new Error(`${res.statusText}`);
  }
  const skill_data = await res.json();

  return skill_data;
};

const updateSkill = async (skill_id: string, skill: Skill) => {
  const flattenedActions = flattenTargetBoundingRect(skill.recorded_actions);
  const res = await fetch(`${BASE_URL}/skill/${skill_id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...skill,
      recorded_actions: flattenedActions
    })
  });

  const skill_data = await res.json();

  return skill_data;
};

const getSkill = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const skill_data = await res.json();

  return {
    ...skill_data,
    recorded_actions: unflattenTargetBoundingRect(skill_data.recorded_actions)
  };
};

const getSkillsCreateByUser = async (userId: string) => {
  // TODO: Change to skill object instead of IDs
  const res = await fetch(`${BASE_URL}/user/${userId}/skill_ids`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const skill_data = await res.json();

  return skill_data.map((skill: Skill) => ({
    ...skill,
    recorded_actions: unflattenTargetBoundingRect(skill.recorded_actions)
  }));
};

const getSkillsSummaryCreateByUser = async (
  userId: string,
  pageSize: number,
  offset: number
) => {
  const res = await fetch(
    `${BASE_URL}/user/${userId}/skills_summary_v2?pageSize=${pageSize}&offset=${offset}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  const responseData = await res.json();

  return {
    skill_list: responseData.skill_list || [],
    total_count: responseData.total_count || 0
  };
};

const getSkillAnalytics = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}/analytics`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return res.json();
};

const getSkillAudio = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}/audio`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  if (res.status !== 200) {
    throw new Error('Failed to download audio ZIP archive');
  }

  return res.blob();
};

const getSkillWorkflowParameters = async (skillId: string) => {
  const res = await fetch(`${BASE_URL}/skill/${skillId}/workflow_parameters`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const data = await res.json();
  return data.workflow_parameters;
};

export {
  Skill,
  SkillSummary,
  createNewSkill,
  updateSkill,
  getSkill,
  getSkillsCreateByUser,
  getSkillsSummaryCreateByUser,
  getSkillAnalytics,
  getSkillAudio,
  getSkillWorkflowParameters
};
