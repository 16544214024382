import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { Add, ArrowLeft, ArrowRight } from '@mui/icons-material';
import Papa, { ParseResult } from 'papaparse';
import AutomationJobInputTable, {
  AutomationJobInputTableRef
} from '../inputTable';
import { getCompanyWorkflow, getWorkflowDetails } from '../../../api/workflow';
import { useUserContextController } from '../../../context/UserContext';
import { WorkflowBase, WorkflowDetail } from '../../../types/automation';
import DisabledTextField from '../disabledTextField';
import { useSnackbar } from '../../../context/SnackbarContext';
import {
  createAutomationJobV2,
  startJob,
  uploadInputFile
} from '../../../api/automationAgent';
import LoadingTextOverlay from '../../../components/loadingTextOverlay';

const AutomationForm: React.FC = () => {
  const [workflows, setWorkflows] = useState<WorkflowBase[]>([]);
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const [selectedWorkflow, setSelectedWorkflow] = useState('');
  const [workflowDetail, setWorkflowDetail] = useState<WorkflowDetail | null>(
    null
  );
  const [jobName, setJobName] = useState<string>('');
  const [acceptedRows, setAcceptedRows] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const tableRef = useRef<AutomationJobInputTableRef>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/automation');
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const fetchWorkflows = async () => {
    setLoading(true);
    try {
      const response = await getCompanyWorkflow();
      setWorkflows(response);
    } catch (error) {
      showSnackbar('Failed to fetch company workflows: ' + error, 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkflowDetail = async (workflowId: string) => {
    setLoadingProcess(true);
    setLoadingMessage('Fetching workflow details...');
    try {
      const response = await getWorkflowDetails(workflowId);
      setWorkflowDetail(response);
    } catch (error) {
      showSnackbar('Failed to fetch workflow details: ' + error, 'error');
    } finally {
      setLoadingProcess(false);
    }
  };

  const handleChangeWorkflowSelection = (event: SelectChangeEvent<string>) => {
    setSelectedWorkflow(event.target.value);
    fetchWorkflowDetail(event.target.value);
  };

  const handleCSVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !workflowDetail?.input_schema) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<Record<string, any>>) => {
        const csvData = results.data as Record<string, any>[];
        const schemaKeys = workflowDetail.input_schema.map(
          (schema) => schema.name
        );
        const csvKeys = Object.keys(csvData[0] || {});

        const matchedKeys = schemaKeys.filter((key) => csvKeys.includes(key));

        if (matchedKeys.length === 0) {
          showSnackbar(
            'No matching columns found between CSV and input schema.',
            'error'
          );

          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          return;
        }

        const formattedRows = csvData.map((row) => {
          const formattedRow: Record<string, any> = {};
          matchedKeys.forEach((key) => {
            formattedRow[key] = row[key] || '';
          });
          return formattedRow;
        });

        tableRef.current?.handleBulkAddRows(formattedRows);

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      },
      error: () => {
        showSnackbar('Error parsing the CSV file.', 'error');

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    });
  };

  const convertToCSV = (rows: Record<string, any>[]): Blob => {
    if (rows.length === 0) throw new Error('No data to convert to CSV');

    const headers = Object.keys(rows[0]).join(',');
    const values = rows.map((row) => Object.values(row).join(',')).join('\n');

    const csvContent = `${headers}\n${values}`;
    return new Blob([csvContent], { type: 'text/csv' });
  };

  const handleStartJob = async () => {
    try {
      setLoadingProcess(true);
      setLoadingMessage('Creating automation job...');
      const jobData = {
        job_description: jobName,
        workflow_id: workflowDetail?.id || selectedWorkflow || '',
        input_data: {},
        extra_data: {
          ai_workers: 1
        }
      };

      const jobId = await createAutomationJobV2(jobData);
      const csvBlob = convertToCSV(acceptedRows);
      const csvFile = new File([csvBlob], `${jobId}_input.csv`, {
        type: 'text/csv'
      });

      setLoadingMessage('Uploading input file...');
      await uploadInputFile(jobId, csvFile);
      setLoadingMessage('Starting automation job...');
      await startJob(jobId);
      setLoadingMessage('Redirecting to job detail...');

      setTimeout(() => {
        navigate(`/automation/job/${jobId}`);
      }, 1500);
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error: ' + error, 'error');
    }
  };

  useEffect(() => {
    fetchWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      p={1}
      sx={{
        boxSizing: 'border-box',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      <LoadingTextOverlay open={loadingProcess} message={loadingMessage} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Button
          variant="text"
          onClick={handleBack}
          sx={(theme) => ({
            color: theme.customColors.themeNeutralPrimaryColor,
            textTransform: 'capitalize'
          })}
        >
          <ArrowLeft />
          {activeStep === 0 ? 'Back' : 'Job Information'}
        </Button>
        <Typography
          flex={1}
          variant="h6"
          sx={{
            textAlign: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          {activeStep === 0 ? 'Specify Job Information' : 'Specify Job Input'}
        </Typography>
        {activeStep === 1 && (
          <Button
            variant="contained"
            component="label"
            sx={(theme) => ({
              color: theme.customColors.themeSecondary,
              backgroundColor: theme.customColors.themePrimary,
              textTransform: 'capitalize',
              borderRadius: '8px',
              boxShadow: 'none',
              padding: '5px 15px 5px 10px'
            })}
          >
            <Add sx={{ marginRight: '5px' }} /> Add input from CSV
            <input
              ref={fileInputRef}
              type="file"
              accept=".csv"
              onChange={handleCSVUpload}
              style={{ display: 'none' }}
            />
          </Button>
        )}
      </Box>
      {activeStep === 0 && (
        <Box width="50%" sx={{ alignSelf: 'center' }}>
          <Box display="flex" alignItems="center" gap={1} py={1}>
            <Box width="50%">
              <label htmlFor="compact-input" style={{ whiteSpace: 'nowrap' }}>
                Description
              </label>
            </Box>
            <TextField
              id="compact-input"
              size="small"
              value={jobName}
              onChange={(e) => setJobName(e.target.value)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '1px'
                  }
                }
              }}
            />
          </Box>
          <DisabledTextField label="Owner" value={user.fullName} />
          <DisabledTextField label="Number of AI Worker" value="1" />
          <DisabledTextField label="Created At" value={String(new Date())} />
          <Typography flex={1} variant="h6" sx={{ textAlign: 'center' }}>
            Workflow Information
          </Typography>
          <Box display="flex" alignItems="center" gap={1} py={1}>
            <Box width="50%">
              <label htmlFor="workflow-select" style={{ whiteSpace: 'nowrap' }}>
                Workflow
              </label>
            </Box>
            <Select
              id="workflow-select"
              size="small"
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderWidth: '1px'
                  }
                }
              }}
              value={selectedWorkflow}
              onChange={handleChangeWorkflowSelection}
            >
              {workflows.map((workflow) => (
                <MenuItem key={workflow?.id} value={workflow?.id}>
                  {workflow.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <DisabledTextField label="Owner" value={workflowDetail?.creator_id} />
          <DisabledTextField
            label="Details"
            value={workflowDetail?.description}
          />
          <DisabledTextField
            label="Expected Input"
            value={JSON.stringify(workflowDetail?.input_schema)}
          />
          <DisabledTextField
            label="Expected Output"
            value={JSON.stringify(workflowDetail?.output_schema)}
          />
        </Box>
      )}
      {activeStep === 1 && (
        <AutomationJobInputTable
          ref={tableRef}
          inputSchema={workflowDetail?.input_schema}
          acceptedRows={acceptedRows}
          setAcceptedRows={setAcceptedRows}
        />
      )}
      <Box
        display="flex"
        justifyContent={activeStep === 1 ? 'center' : 'end'}
        padding={2}
      >
        {activeStep === 0 ? (
          <Button
            variant="text"
            onClick={handleNext}
            sx={(theme) => ({
              color: theme.customColors.themeNeutralPrimaryColor,
              textTransform: 'capitalize'
            })}
            disabled={!Boolean(workflowDetail) || !Boolean(jobName)}
          >
            Next <ArrowRight />
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={(theme) => ({
              color: theme.customColors.themeSecondary,
              backgroundColor: theme.customColors.themePrimary,
              textTransform: 'capitalize',
              borderRadius: '30px',
              px: '20px',
              boxShadow: 'none'
            })}
            disabled={acceptedRows.length < 1}
            onClick={handleStartJob}
          >
            Start Job
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AutomationForm;
