import { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from '@mui/material';
import { Add, ArrowLeft } from '@mui/icons-material';
import Pagination from '../../../components/pagination';
import { downloadOutputFile } from '../../../api/automationAgent';
import Papa from 'papaparse';
import LoadingTextOverlay from '../../../components/loadingTextOverlay';
import { useSnackbar } from '../../../context/SnackbarContext';
import { useUserContextController } from '../../../context/UserContext';

interface OutputRowData {
  company: string;
  integrations: string;
  integrationType: string;
}

const AutomationJobOutput: React.FC = () => {
  const { jobId } = useParams();
  const [rows, setRows] = useState<OutputRowData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const pageSize = 10;
  const totalPages = Math.ceil(rows.length / pageSize);

  useEffect(() => {
    fetchAndDisplayOutputFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const fetchAndDisplayOutputFile = async () => {
    try {
      const csvBlob = await downloadOutputFile(jobId || '');
      const csvText = await csvBlob.text();
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data as OutputRowData[];
          setRows(parsedData);
          setLoading(false);
        },
        error: (error: any) => {
          showSnackbar('Error parsing job output: ' + error, "error");
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      setLoadingProcess(true);
      setLoadingMessage("Preparing file to download...")
      const csvBlob = await downloadOutputFile(jobId || '');
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${jobId}_output.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      showSnackbar("File downloaded", "success");
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, "error");
    } finally {
      setLoadingProcess(false);
    }
  };

  const handleBack = () => {
    navigate(`/automation/job/${jobId}`);
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedRows = rows.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{ color: '#2196f3' }} />
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      p={1}
      sx={{
        boxSizing: 'border-box',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      <LoadingTextOverlay open={loadingProcess} message={loadingMessage} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Button
          variant="text"
          onClick={handleBack}
          sx={(theme) => ({
            color: theme.customColors.themeNeutralPrimaryColor,
            textTransform: 'capitalize'
          })}
        >
          <ArrowLeft />
          Job Information
        </Button>
        <Typography
          flex={1}
          variant="h6"
          sx={{
            textAlign: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          Job Output
        </Typography>
        <Button
          variant="contained"
          onClick={handleDownloadCSV}
          sx={(theme) => ({
            color: theme.customColors.themeSecondary,
            backgroundColor: theme.customColors.themePrimary,
            textTransform: 'capitalize',
            borderRadius: '8px',
            boxShadow: 'none',
            padding: '5px 15px 5px 10px',
          })}
          disabled={!(rows.length > 0)}
        >
          <Add sx={{ marginRight: '5px' }} /> Download CSV
        </Button>
      </Box>
      {rows.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(rows[0]).map((key) => (
                  <TableCell key={key} sx={{ fontWeight: 'bold' }}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.map((row, index) => (
                <TableRow key={index}>
                  {Object.values(row).map((value, i) => (
                    <TableCell key={i}>{value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box m={2}>
          <Typography sx={{ fontSize: '1rem', color: 'gray' }}>No output available for this job.</Typography>
        </Box>
      )}
      <Box
        sx={{
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handleChangePage}
        />
      </Box>
    </Box>
  );
};

export default AutomationJobOutput;
