import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Document {
  title: string;
  date: string;
}

interface Agent {
  name: string;
  color: string;
  documents: Document[];
}

const AgentDashboard = (props: { agents: Agent[] }) => {
  const { agents } = props;
  return (
    <Box
      sx={{
        padding: '20px',
        overflowY: 'auto'
      }}
    >
      {agents.map((agent, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: agent.color,
            borderRadius: '10px',
            padding: '15px',
            marginBottom: '20px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {agent.name}
            </Typography>
            <Button variant="text" sx={{ textTransform: 'none' }}>
              Edit Agent
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '10px',
              gap: '10px'
            }}
          >
            {agent.documents.map((doc: Document, docIndex: number) => (
              <Card key={docIndex} sx={{ width: '150px' }}>
                <CardContent>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    {doc.title}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {doc.date}
                  </Typography>
                </CardContent>
              </Card>
            ))}
            <Box
              sx={{
                width: '150px',
                height: '100px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <AddIcon color="primary" />
              <Typography color="primary">Teach SOP</Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default AgentDashboard;
