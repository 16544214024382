import { Box, Typography, Button, Modal, TextField } from '@mui/material';

interface NewAgentModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (agentName: string) => void;
  agentName: string;
  onAgentNameChange: (name: string) => void;
}

const NewAgentModal = ({
  open,
  onClose,
  onSave,
  agentName,
  onAgentNameChange
}: NewAgentModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-agent-modal"
      aria-describedby="modal-to-add-new-agent"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Add New Agent
        </Typography>
        <TextField
          fullWidth
          label="Agent Name"
          value={agentName}
          onChange={(e) => onAgentNameChange(e.target.value)}
          sx={{ mb: 3 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={() => onSave(agentName)} variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NewAgentModal;
