// components/LoadingOverlay.tsx
import React from 'react';
import { alpha, Box, CircularProgress, Typography } from '@mui/material';

interface LoadingTextOverlayProps {
  open: boolean;
  message: string;
}

const LoadingTextOverlay: React.FC<LoadingTextOverlayProps> = ({ open, message }) => {
  if (!open) return null;

  return (
    <Box
      sx={(theme) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: alpha(theme.customColors.themeNeutralPrimaryColor, 0.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      })}
    >
      <CircularProgress />
      <Typography variant="h6" sx={(theme) => ({ mt: 2, color: theme.customColors.themeSecondary })}>
        {message}
      </Typography>
    </Box>
  );
};

export default LoadingTextOverlay;
