import { useEffect, useState } from 'react';
import { useParams, Link, useNavigate, Navigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getAutomationJobV2, downloadOutputFile } from '../../../api/automationAgent';
import { AutomationJob } from '../../../types/automation';
import { statusStyles } from '../../../utils/automation';
import { Add } from '@mui/icons-material';
import Papa from 'papaparse';
import { useSnackbar } from '../../../context/SnackbarContext';
import LoadingTextOverlay from '../../../components/loadingTextOverlay';
import { useUserContextController } from '../../../context/UserContext';

interface TableRowData {
  company: string;
  integrations: string;
  integrationType: string;
}

const AutomationJobDetails = () => {
  const { jobId } = useParams();
  const [job, setJob] = useState<AutomationJob | null>(null);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<TableRowData[]>([]);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getAutomationJobV2(jobId || '')
      .then((response) => {
        setJob(response);
        if (response.status === 'completed') {
          fetchAndDisplayOutputFile();
        }
      })
      .catch((error) => {
        showSnackbar('Error fetching job details: ' + error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  const fetchAndDisplayOutputFile = async () => {
    try {
      const csvBlob = await downloadOutputFile(jobId || '');
      const csvText = await csvBlob.text();
      Papa.parse(csvText, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data as TableRowData[];
          setRows(parsedData.slice(0, 5));
        },
        error: (error: any) => {
          showSnackbar('Error parsing job output: ' + error, "error");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      setLoadingProcess(true);
      setLoadingMessage("Preparing file to download...")
      const csvBlob = await downloadOutputFile(jobId || '');
      const url = window.URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${jobId}_output.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      showSnackbar("File downloaded", "success");
    } catch (error) {
      setLoadingProcess(false);
      showSnackbar('Error downloading file: ' + error, "error");
    } finally {
      setLoadingProcess(false);
    }
  };

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress sx={{ color: '#2196f3' }} />
      </Box>
    );
  }

  if (!job) {
    return (
      <Box sx={{ p: 3, minHeight: '100vh' }}>
        <Typography variant="h6" color="error">
          Job not found
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        minHeight: '100vh',
        boxSizing: 'border-box',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      <LoadingTextOverlay open={loadingProcess} message={loadingMessage} />
      <Box
        sx={{
          maxWidth: '800px',
          margin: '0 auto'
        }}
      >
        <Box sx={{ mb: 3 }}>
          <Link
            to="/automation"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Typography
              sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: theme.customColors.themeNeutralPrimaryColor,
                '&:hover': { color: theme.customColors.themeNeutralSecondaryColor }
              })}
            >
              <ArrowBackIcon fontSize="small" />
              Back to Automation Jobs
            </Typography>
          </Link>
        </Box>

        <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
          Automation Job Details
        </Typography>

        {/* Job ID, Description, and Status */}
        <Box sx={(theme) => ({ mb: 3, p: 2, borderRadius: 1, '&:hover': { bgcolor: theme.customColors.themeTertiary } })}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Job ID</Typography>
          <Typography sx={{ fontSize: '1.1rem' }}>{job.id}</Typography>
        </Box>

        <Box sx={(theme) => ({ mb: 3, p: 2, borderRadius: 1, '&:hover': { bgcolor: theme.customColors.themeTertiary } })}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Description</Typography>
          <Typography sx={{ fontSize: '1.1rem' }}>{job.job_description}</Typography>
        </Box>

        <Box sx={(theme) => ({ mb: 3, p: 2, borderRadius: 1, '&:hover': { bgcolor: theme.customColors.themeTertiary }, display: 'flex', alignItems: 'center', gap: 4, flexWrap: 'wrap' })}>
          <Box>
            <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Status</Typography>
            <Typography sx={{ display: 'inline-block', px: 2, py: 0.5, borderRadius: 1, textTransform: 'capitalize', fontSize: '0.9rem', fontWeight: 500, ...statusStyles[job.status] }}>{job.status}</Typography>
          </Box>

          {job.extra_data?.number_of_actions && (
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Total Actions</Typography>
              <Typography sx={{ fontSize: '1.1rem' }}>{job.extra_data.number_of_actions}</Typography>
            </Box>
          )}
        </Box>

        {/* Job Output Table */}
        <Box sx={(theme) => ({ mb: 3, p: 2, borderRadius: 1, '&:hover': { bgcolor: theme.customColors.themeTertiary } })}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>Job Output</Typography>
          {rows.length > 0 ? (
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {Object.keys(rows[0]).map((key) => (
                      <TableCell key={key} sx={{ fontWeight: 'bold' }}>{key}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      {Object.values(row).map((value, i) => (
                        <TableCell key={i} sx={{ wordBreak: 'break-word' }}>{value}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ fontSize: '1rem', color: 'gray' }}>No output available for this job.</Typography>
          )}

          <Box display="flex" flexDirection="row" justifyContent="end" mt={1}>
            <Button
              variant="contained"
              onClick={handleDownloadCSV}
              sx={(theme) => ({
                color: theme.customColors.themeSecondary,
                backgroundColor: theme.customColors.themePrimary,
                textTransform: 'capitalize',
                borderRadius: '8px',
                boxShadow: 'none',
                padding: '5px 15px 5px 10px',
                alignSelf: 'center'
              })}
              disabled={!(rows.length > 0)}
            >
              <Add sx={{ marginRight: '5px' }} /> Download CSV
            </Button>
            {
              rows.length > 0 && (
              <Button
                variant="text"
                sx={(theme) => ({
                  color: theme.customColors.themePrimary,
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                  padding: 0,
                  alignSelf: 'center',
                  marginLeft: '16px',
                  fontWeight: 'bold'
                })}
                onClick={() => navigate(`/automation/output/${jobId}`)}
              >
                See More...
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AutomationJobDetails;
