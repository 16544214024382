import React, { useState, useImperativeHandle, forwardRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Add, DeleteOutlined, Check } from '@mui/icons-material';
import { ExpectedInput } from '../../../types/automation';

interface AutomationJobInputProps {
  inputSchema?: ExpectedInput[];
  acceptedRows: Record<string, any>[];
  setAcceptedRows: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
}

export interface AutomationJobInputTableRef {
  handleBulkAddRows: (newRows: Record<string, any>[]) => void;
}

const AutomationJobInputTable = forwardRef<
  AutomationJobInputTableRef,
  AutomationJobInputProps
>(({ inputSchema, acceptedRows, setAcceptedRows }, ref) => {
  const [inputRows, setInputRows] = useState<Record<string, any>[]>([]);

  const MAX_ROWS = 100;

  useImperativeHandle(ref, () => ({
    handleBulkAddRows(newRows: Record<string, any>[]) {
      const totalRows = acceptedRows.length + inputRows.length + newRows.length;
      if (totalRows > MAX_ROWS) {
        alert(`Cannot add more than ${MAX_ROWS} rows.`);
        return;
      }

      const [completeRows, incompleteRows] = newRows.reduce<
        [Record<string, any>[], Record<string, any>[]]
      >(
        (
          [complete, incomplete]: [
            Record<string, any>[],
            Record<string, any>[]
          ],
          row: Record<string, any>
        ) => {
          const isComplete = inputSchema!.every(
            (schema) =>
              row[schema.name] !== undefined && row[schema.name] !== ''
          );
          if (isComplete) complete.push(row);
          else incomplete.push(row);
          return [complete, incomplete];
        },
        [[], []]
      );

      setAcceptedRows((prev) => [...prev, ...completeRows]);
      setInputRows((prev) => [...prev, ...incompleteRows]);
    }
  }));

  const handleAddInputRow = () => {
    if (inputSchema && acceptedRows.length + inputRows.length < MAX_ROWS) {
      const emptyRow = inputSchema.reduce(
        (acc, schema) => {
          acc[schema.name] = '';
          return acc;
        },
        {} as Record<string, any>
      );
      setInputRows([...inputRows, emptyRow]);
    }
  };

  const handleAcceptInput = (index: number) => {
    if (acceptedRows.length + inputRows.length - 1 < MAX_ROWS) {
      setAcceptedRows((prev) => [...prev, inputRows[index]]);
      setInputRows(inputRows.filter((_, i) => i !== index));
    }
  };

  const handleDeleteRow = (index: number) => {
    setAcceptedRows((prev) => prev.filter((_, i) => i !== index));
  };

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedInputRows = inputRows.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );
    setInputRows(updatedInputRows);
  };

  if (!inputSchema) {
    return (
      <Box
        flex={1}
        overflow="auto"
        my={1}
        sx={{
          maxHeight: '100%',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Safari, and Edge
          }
        }}
      >
        <Typography variant="h6" align="center" sx={{ my: 2 }}>
          No schema provided.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      flex={1}
      overflow="auto"
      my={1}
      sx={{
        maxHeight: '100%',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none' // For Chrome, Safari, and Edge
        }
      }}
    >
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {inputSchema.map((schema) => (
                <TableCell
                  key={schema.name}
                  sx={{ fontWeight: 'bold', textTransform: 'none' }}
                >
                  {schema.name}
                </TableCell>
              ))}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {acceptedRows.map((row, index) => (
              <TableRow key={index}>
                {inputSchema.map((schema) => (
                  <TableCell key={schema.name} sx={{ wordBreak: 'break-word' }}>
                    {row[schema.name]}
                  </TableCell>
                ))}
                <TableCell align="right" padding="none">
                  <IconButton onClick={() => handleDeleteRow(index)}>
                    <DeleteOutlined
                      sx={(theme) => ({
                        color: theme.customColors.themeDanger
                      })}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}

            {/* Input Rows */}
            {inputRows.map((inputRow, index) => (
              <TableRow key={`input-${index}`}>
                {inputSchema.map((schema) => (
                  <TableCell key={schema.name} sx={{ wordBreak: 'break-word' }}>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={inputRow[schema.name]}
                      onChange={(e) =>
                        handleInputChange(index, schema.name, e.target.value)
                      }
                    />
                  </TableCell>
                ))}
                <TableCell align="right" padding="none">
                  <IconButton onClick={() => handleAcceptInput(index)}>
                    <Check
                      sx={(theme) => ({
                        color: theme.customColors.themePrimary
                      })}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="text"
        sx={(theme) => ({
          textTransform: 'capitalize',
          color: theme.customColors.themeNeutralPrimaryColor
        })}
        onClick={handleAddInputRow}
        disabled={acceptedRows.length + inputRows.length >= MAX_ROWS}
      >
        <Add /> Click to add more
      </Button>
    </Box>
  );
});

export default AutomationJobInputTable;
