import { BASE_URL } from './constants';
import { getUserSessionCookie } from '../utils/SessionCookie';

export async function getCompanyWorkflow(): Promise<any> {
  const url = `${BASE_URL}/workflow/company`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    return await response.json();
  } catch (error) {
    console.error('Error getting company workflows:', error);
    throw error;
  }
}

export async function getWorkflowDetails(
  workflowId: string
): Promise<any> {
  const url = `${BASE_URL}/workflow/${workflowId}`;
  const userSessionToken = getUserSessionCookie();

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userSessionToken}`
      }
    });

    return await response.json();
  } catch (error) {
    console.error('Error getting company workflows:', error);
    throw error;
  }
}
