// NavigationButton.tsx
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { SvgIconComponent } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Theme, useTheme } from '@mui/material';

interface NavigationButtonProps extends NavLinkProps {
  icon: SvgIconComponent;
  text: string;
  disabled?: boolean;
}

const StyledNavLink = styled(NavLink)<{ theme: Theme; disabled?: boolean }>(
  ({ theme, disabled }) => ({
    width: '100%',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0',
    color: theme.customColors.themeNeutralPrimaryColor,
    fontSize: '10px',
    lineHeight: '12px',
    marginBottom: '10px',
    boxSizing: 'border-box',
    borderRadius: '20px',
    textTransform: 'capitalize',
    textDecoration: 'none',
    pointerEvents: disabled ? 'none' : 'auto',
    ':hover': {
      color: theme.customColors.themePrimary,
      backgroundColor: theme.customColors.themeSecondary
    },
    '&:hover .icon-wrapper': {
      backgroundColor: theme.customColors.themeSecondary
    },
    '&.active': {
      color: theme.customColors.themePrimary,
      backgroundColor: theme.customColors.themeSecondary
    }
  })
);

const IconWrapper = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '24px', // Fixed width for the icon wrapper
  height: '24px', // Fixed height to make it square
  padding: '8px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '2px', // Minimal margin to place text right below the icon
  '&.active': {
    color: theme.customColors.themePrimary,
    backgroundColor: theme.customColors.themeSecondary
  }
}));

const WebNavButton: React.FC<NavigationButtonProps> = ({
  icon: Icon,
  text,
  to,
  disabled,
  ...props
}) => {
  const theme = useTheme();
  return (
    <StyledNavLink to={to} theme={theme} disabled={disabled} {...props}>
      {({ isActive }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: '1px 10px 1px 10px',
            boxSizing: 'border-box',
            width: '100%',
          }}
        >
          <IconWrapper
            theme={theme}
            className={`icon-wrapper ${isActive ? 'active' : ''}`}
          >
            <Icon style={{ fontSize: '24px' }} />
          </IconWrapper>
          <span style={{ fontSize: '16px' }}>{text}</span>
        </div>
      )}
    </StyledNavLink>
  );
};

export default WebNavButton;
