import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AgentDashboard from './AgentDashboard';
import { useState } from 'react';
import NewAgentModal from './NewAgentModal';

const initialAgents = [
  {
    name: 'Product Team AI',
    color: '#DFF1FF',
    documents: [
      { title: 'AF01 - Salesforce Payroll System', date: '22 May 2024' },
      { title: 'AF02 - Salesforce Payment Invoice', date: '23 May 2024' },
      { title: 'AF03 - Salesforce Transaction System', date: '22 May 2024' }
    ]
  },
  {
    name: 'Sales Team AI',
    color: '#FFD7D7',
    documents: []
  }
];

const AIFleetPage = () => {
  const [agents, setAgents] = useState(initialAgents);
  const [open, setOpen] = useState(false);
  const [newAgentName, setNewAgentName] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSave = (name: string) => {
    if (newAgentName.trim()) {
      const colors = ['#DFF1FF', '#FFD7D7', '#D7FFD7', '#FFD7FF'];
      const newAgent = {
        name: newAgentName,
        color: colors[agents.length % colors.length],
        documents: []
      };
      setAgents([...agents, newAgent]);
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        padding: '20px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600
          }}
        >
          All agents
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: '20px',
            backgroundColor: (theme) => theme.customColors.themePrimary
          }}
          onClick={handleOpen}
        >
          <AddIcon />
          Add agent
        </Button>
      </Box>
      <AgentDashboard agents={agents} />
      <NewAgentModal
        open={open}
        onClose={handleClose}
        onSave={handleSave}
        agentName={newAgentName}
        onAgentNameChange={setNewAgentName}
      />
    </Box>
  );
};

export default AIFleetPage;
