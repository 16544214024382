import { useNavigate } from 'react-router-dom';
import LoginComponent from '../../components/login/LoginComponent';
import { useUserContextController } from '../../context/UserContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { ExtensionName, getExtensionName } from '../../utils/ManifestChecker';

interface HomePageProps {
  isWebApp: boolean;
}

const HomePage = (props: HomePageProps) => {
  const navigate = useNavigate();
  const [userContext] = useUserContextController();
  const { user } = userContext;
  const extensionName = props.isWebApp ? null : getExtensionName();

  if (!user) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <LoginComponent />
      </Box>
    );
  }

  const title =
    extensionName !== ExtensionName.SOPAI
      ? 'Dokai here to help you. To teach me please select Teach Mode, if you want to find all learned skills go to skills tab.'
      : 'SOPAI here to help you. To create new document please select Teach Mode, if you want to find all documents go to skills tab.';

  return (
    <Box
      sx={{
        padding: '10px',
        marginTop: '20px',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        alignSelf: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography
        component="div"
        sx={{
          marginBottom: '10px',
          fontWeight: '600',
          lineHeight: '24px',
          fontSize: '16px'
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          Hi, {extensionName !== ExtensionName.SOPAI ? 'Dokai' : 'SOPAI'} here{' '}
          <WavingHandIcon
            sx={(theme) => ({
              color: theme.customColors.themeEmojiYellow,
              marginLeft: '5px',
              alignSelf: 'center'
            })}
          />
        </Box>
      </Typography>
      <Typography>{title}</Typography>
      <Grid
        container
        spacing={2}
        sx={{ marginTop: '20px' }}
        justifyContent="center"
      >
        {extensionName !== ExtensionName.SOPAI && (
          <Grid item xs={6}>
            <Button
              sx={(theme) => ({
                color: theme.customColors.themeNeutralPrimaryColor,
                backgroundColor: theme.customColors.themeSecondary,
                border: `1px solid ${theme.customColors.themeTertiary}`,
                padding: '10px 6px',
                lineHeight: '18px',
                fontSize: '12px',
                textTransform: 'capitalize',
                ':hover': {
                  backgroundColor: theme.customColors.themeSecondary,
                  color: theme.customColors.themePrimary
                }
              })}
              variant="contained"
              fullWidth
              startIcon={<ChatIcon />}
              onClick={() => navigate('/guide')}
            >
              Guide Mode
            </Button>
          </Grid>
        )}
        {!props.isWebApp && user.isManager && (
          <Grid item xs={6}>
            <Button
              sx={(theme) => ({
                color: theme.customColors.themeNeutralPrimaryColor,
                backgroundColor: theme.customColors.themeSecondary,
                border: `1px solid ${theme.customColors.themeTertiary}`,
                padding: '10px 6px',
                lineHeight: '18px',
                fontSize: '12px',
                textTransform: 'capitalize',
                ':hover': {
                  backgroundColor: theme.customColors.themeSecondary,
                  color: theme.customColors.themePrimary
                }
              })}
              variant="contained"
              fullWidth
              startIcon={<MenuBookIcon />}
              onClick={() => navigate('/teach')}
            >
              Teach Mode
            </Button>
          </Grid>
        )}
        {(user.isManager || extensionName === ExtensionName.SOPAI) && (
          <Grid item xs={6}>
            <Button
              sx={(theme) => ({
                color: theme.customColors.themeNeutralPrimaryColor,
                backgroundColor: theme.customColors.themeSecondary,
                border: `1px solid ${theme.customColors.themeTertiary}`,
                padding: '10px 6px',
                lineHeight: '18px',
                fontSize: '12px',
                textTransform: 'capitalize',
                ':hover': {
                  backgroundColor: theme.customColors.themeSecondary,
                  color: theme.customColors.themePrimary
                }
              })}
              variant="contained"
              fullWidth
              startIcon={<AutoFixHighIcon />}
              onClick={() => navigate('/documentation/list')}
            >
              Skills
            </Button>
          </Grid>
        )}
        {extensionName !== ExtensionName.SOPAI && (
          <Grid item xs={6}>
            <Button
              sx={(theme) => ({
                color: theme.customColors.themeNeutralPrimaryColor,
                backgroundColor: theme.customColors.themeSecondary,
                border: `1px solid ${theme.customColors.themeTertiary}`,
                padding: '10px 6px',
                lineHeight: '18px',
                fontSize: '12px',
                textTransform: 'capitalize',
                ':hover': {
                  backgroundColor: theme.customColors.themeSecondary,
                  color: theme.customColors.themePrimary
                }
              })}
              variant="contained"
              fullWidth
              startIcon={<SettingsIcon />}
              onClick={() => navigate('/profile')}
            >
              Settings
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default HomePage;
